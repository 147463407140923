import * as React from 'react'
import {SectionNotification as SectionNotificationTPA} from 'wix-ui-tpa/SectionNotification'
import classNames from 'classnames'
import {classes} from './section-notification.st.css'
import {SectionNotificationProps} from '.'

export const SectionNotification = ({text, icon, className}: SectionNotificationProps) => (
  <SectionNotificationTPA className={classNames(classes.root, className)}>
    {icon && <SectionNotificationTPA.Icon icon={icon} />}
    <SectionNotificationTPA.Text>{text}</SectionNotificationTPA.Text>
  </SectionNotificationTPA>
)
